
	// eslint-disable-next-line
	    import { getPlatforms, IonContent, IonHeader, IonToolbar, IonPage, IonImg, IonFooter, IonText, IonGrid, IonCol, IonRow } from '@ionic/vue';
	    import { defineComponent, ref } from 'vue';
	    export default defineComponent({
	        /*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
	        // eslint-disable-next-line
	    name: "Home",
	      components: { IonContent, IonHeader, IonToolbar, IonPage, IonImg, IonFooter, IonText, IonGrid, IonCol, IonRow },

		  setup () {
			const mobile = () => { return  getPlatforms().includes("desktop") };


			return { mobile }
		  }




	    });
